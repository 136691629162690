
import { Vue, Component } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class Dash extends Vue {
  private mounted () {
    // 
  }
  private findDoctor () {
    this.$router.push('/hxqClinic/doctor/list')
  }
}
